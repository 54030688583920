<template>
  <div :class="{ 'padding-trivia': isActive }" style="
        background-image: url('img/FondoLuquinCompleto.webp');">
    <!--       background-repeat: no-repeat;
          background-size: 100% auto;-->
    <router-view></router-view>

    <footer v-if="currentRoute !== '/trivia' && currentRoute !== '/trivia-podium'"
      class="text-center text-md-start text-white p-0 footer">
      <!-- Section: Social media -->
      <!--  -->
      <!-- Section: Social media -->

      <!-- Section: Links  -->
      <section class="col-12">
        <div class="row" style="padding: 10pt 0% 10pt 0%">
          <!-- Grid column -->
          <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pl-5 pr-5"
            style="display: flex; align-items: center; justify-content: left">
              <img class="" style="width: 200px; margin-left: auto; margin-right: auto;" src="img/LogoAntonioLuquin.webp" alt="" />
          </div>

          <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 text-capitalize" style="">
            <strong>
              <p class="" style="text-align: center;
                  font-family: 'Montserrat', sans-serif;
                  font-size: 10pt;
                  margin: 0;
                ">
                <i class="fas fa-home"></i> |
                <strong> Avenida siria 2345 </strong><br />
                <strong>San Miguel de Tucumán</strong> <br />
                <strong>Tucumán, Argentina </strong> <br />
              </p>
            </strong>
          </div>

          <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <p style="text-align: center;font-family: 'Montserrat', sans-serif; font-size: 10pt;margin: 0">
              <i class="fas fa-envelope"></i>
              <strong>rrhh@luquin.com.ar</strong>
            </p>
            <p style="text-align: center;font-size: 10pt; margin: 0">
              <i class="fas fa-phone"></i><strong>(0381) 4552000</strong>
            </p>
          </div>
          <!-- Grid column -->
        </div>
      </section>
      <!-- Section: Links  -->
    </footer>
  </div>
</template>
<script>
import { Tabs, TabPane } from "@/components";

export default {
  name: "layout-page",
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  },
  data() {
    return {
      isActive: this.$route.path !== '/trivia' && this.$route.path !== '/trivia-podium', // Cambia esto según tu lógica condicional
    };
  }
};
</script>

<style scoped>
.footer {
  background-color: #2781ab;
}
.padding-trivia {
  /* Estilos CSS para cuando isActive es true */
  padding: 60pt 0% 0% 0%;
}
</style>