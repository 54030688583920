<template>
  <div>
    <div class="container mb-5">
      <div
        class="shadow button-container m-auto"
        style="background-color: white"
      >
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="card m-0 border-blue">
              <div class="row">
                <div class="col-md-4 img-portada">
                  <!-- Second image on the left side of the article -->
                  <img src="img/rrhh.PNG" class="i" alt="..." />
                </div>
                <div class="col-md-8">
                  <div class="title-card">
                    Sumate a nuestro Equipo y trabajá con nosotros
                  </div>
                  <div class="text-secondary-card">
                    Si estás interesado en sumarte al equipo de personas que
                    trabaja en nuestra empresa podés hacer lo siguiente:
                  </div>

                  <div class="text-secondary-card separator">
                    Dejanos tus datos para futuras búsquedas laborales mediante
                    el botón <b>"Dejanos tu CV".</b>
                  </div>
                  <div class="pl-4 pr-4 pt-2">
                    <div
                      class="col-md-5 col-sm-5 bton btn-input pointer"
                      @click="openPostulantPage(true)"
                    >
                      Dejanos tu CV
                    </div>
                  </div>
                </div>
              </div>
              <div class="separador-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5">
      <div
        class="shadow button-container m-auto"
        style="background-color: white"
      >
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="card m-0 border-blue">
              <div class="row">
                <div class="col-md-12">
                  <div class="pl-3">
                    <div class="title-card">Oportunidades laborales</div>
                    <div class="text-secondary-card">
                      También podés aplicar y postularte a una de las ofertas
                      laborales que están vigentes en esta sección. Haz click
                      sobre la oferta para ver más detalles.
                    </div>
                  </div>
                  <div class="col-12 p-3 mb-1">
                    <div class="row">
                      <div
                        v-for="(item, index) in jobOffers"
                        :key="index"
                        class="card-notice col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4 p-0 p-5"
                      >
                        <div
                          class="col-12 p-0 container-img"
                          v-if="item.files.length"
                        >
                          <img
                            class="pointer"
                            style="width: 100%; height: 100%; object-fit: cover"
                            :src="url + item.files[0].path.substring(1)"
                            alt="..."
                            @click="openJobOfferModal(item)"
                          />
                        </div>
                        <div class="col-12 p-0 notice">
                          <div class="subtitle">{{ item.title }}</div>
                        </div>
                      </div>
                    </div>

                    <!-- Button trigger modal overflow: hidden;-->
                    <!-- <n-button type="primary" @click.native="modals.classic = true">
                      Launch Modal
                    </n-button> -->

                    <!-- Classic Modal -->
                    <modal
                      :show.sync="modals.classic"
                      headerClasses="justify-content-center"
                      modalClasses="custom-style-modal"
                    >
                      <alert type="success" v-if="alertShowSuccess">
                        <div class="alert-icon">
                          <i class="now-ui-icons ui-1_check"></i>
                        </div>
                        <strong>Te postulaste correctamente!</strong>
                      </alert>

                      <alert type="danger" v-if="alertShowDanger">
                        <div class="alert-icon">
                          <i class="now-ui-icons ui-1_simple-remove"></i>
                        </div>
                        <strong>{{ alertTextDanger }}</strong>
                        <br />
                      </alert>
                      <div
                        class="col-12 p-0 container-img center-image"
                        v-if="imagesJobOffer"
                      >
                        <img
                          class="custom-style-modal-image"
                          alt="..."
                          style="width: 100%; height: 200%; object-fit: cover"
                          :src="url + imagesJobOffer.path"
                        />
                      </div>
                      <template slot="footer">
                        <!-- <n-button class="bton btn-input col-md-12 col-sm-12 pointer mt-2"
                          @click.native="modals.classic = false">Cerrar</n-button> -->
                        <div class="row">
                          <div class="text-secondary-card2">
                            <small> ingresa tu DNI para postularte </small>
                          </div>
                          <div class="col-9">
                            <input
                              type="number"
                              name="input_dni"
                              id="input_dni"
                              class="input-postulation"
                              placeholder=" ingresa tu DNI"
                              v-model="dni"
                            />
                          </div>
                          <div class="col-3">
                            <div
                              class="bton btn-input col-md-12 col-sm-12 pointer"
                              @click="findPostulant"
                            >
                            <small>

                              Postularse
                            </small>
                            </div>
                          </div>
                        </div>
                      </template>
                    </modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane, Modal, Button, Alert } from "@/components";
import config from "../../config";

export default {
  name: "rrhh",
  data() {
    return {
      jobOffers: [],
      idJobOffer: "",
      titleJobOffer: "Trabaja con nosotros",
      imagesJobOffer: [],
      // url: null,
      url:
        process.env.NODE_ENV === "development"
          ? config.developmentUrl
          : config.productionUrl,
      modals: {
        classic: false,
      },
      dni: "",
      alertShowDanger: false,
      alertTextDanger: "",
      alertShowSuccess: false,
    };
  },
  components: {
    Modal,
    [Button.name]: Button,
    Alert,
  },
  mounted() {
    //this.checkProduction();
    this.getJobOffers();
  },
  methods: {
      async getJobOffers() {
      const response = await fetch(`${this.url}api/jobOffer/getJobOffers`);
      const data = await response.json();
      this.jobOffers = data;
    },
    openJobOfferModal(item) {
      this.imagesJobOffer = {
        path: item.files[0].path.substring(1),
        title: item.title,
      };
      this.idJobOffer = item.id;
      this.titleJobOffer = item.title;
      this.dni = "";
      this.modals.classic = true;
    },
    openPostulantPage(visible) {
      this.$router.push({
        name: "postulation",
        params: {
          areas: visible,
          idJobOffer: this.idJobOffer,
          titleJobOffer: this.titleJobOffer,
          dni: this.dni,
        },
      });
    },
    async findPostulant() {
      if (this.validaDNI(this.dni)) {
        const response = await fetch(
          `${this.url}api/postulant/findPostulant/${this.dni}`
        );
        const data = await response.json();
        data.length == 0 ? this.openPostulantPage(false) : this.applyToJob();
      }
    },
    async applyToJob() {
      const response = await fetch(
        `${this.url}api/postulant/findPostulantJobOffer/${this.dni}`
      );
      const dataJobOffer = await response.json();

      let job = [];
      dataJobOffer.forEach(function (jo) {
        job.push(jo.id);
      });

      if (!job.includes(this.idJobOffer)) {
        const data = { dni: this.dni, idJobOffer: this.idJobOffer };

        fetch(`${this.url}api/postulant/applyToJobOffer/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(data),
        });

        this.alertShowSuccess = true;
        setTimeout(() => this.alertSuccessShowHide(), 2000);
      } else {
        this.alertTextDanger = "Ya te postulaste a esta oferta";
        this.alertShowDanger = true;
        setTimeout(() => this.alertDangerShowHide(), 2000);
      }

      this.titleJobOffer = "Trabaja con nosotros";
      this.dni = "";
    },
    validaDNI(dni) {
      let dni_without_left_zeros = Number(dni);
      let ex_regular_dni = /^[\d]{1,2}[\d]{3,3}[\d]{3,3}$/;
      if (ex_regular_dni.test(dni_without_left_zeros)) {
        return true;
      } else {
        this.alertShowDanger = true;
        this.alertTextDanger = "Formato del dni inválido";
        setTimeout(() => this.alertDangerShowHide(), 2000);
        return false;
      }
    },
    alertDangerShowHide() {
      this.alertShowDanger = !this.alertShowDanger;
      this.alertTextDanger = "";
    },
    alertSuccessShowHide() {
      this.alertShowSuccess = !this.alertShowSuccess;
      this.dni = "";
      this.modals.classic = false;
    },
  },
};
</script>

<style scoped>
.center-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-postulation {
  width: 100%;
  border: 2px solid;
  border-radius: 2px;
  padding: 0;
  margin: 0;
}

.border-blue {
  border-style: solid !important;
  border-width: 0 0 6pt 0 !important;
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
}

.title-card {
  padding: 50pt 50pt 0 20pt;
  font-weight: 900;
  font-size: 25pt;
  text-align: left;
  color: rgb(33, 135, 172);
}

.subtitle {
  padding: 5pt 0pt 0 0pt;
  font-weight: 900;
  font-size: 14pt;
  text-align: center;
  color: rgb(33, 135, 172);
}

.text-secondary-card {
  padding: 10pt 10pt 0 10pt;
  font-weight: 500;
  font-size: 10pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.text-secondary-card2 {

  font-weight: 500;
  font-size: 10pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.btn-input {
  background-color: rgb(33, 135, 172);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  margin-top: 25pt !important;
  margin-left: 20pt;
  padding-top: 2pt;
  border-style: solid;
  border-width: 0 0 0 4pt;
}

.bton {
  height: 23pt;
}

@media (max-width: 1200px) {
  .title-card {
    padding: 45pt 40pt 0 15pt;
    font-size: 18pt;
  }

  .text-secondary-card {
    margin-top: 10pt !important;
    padding: 15pt 50pt 0 20pt;
    font-weight: 500;
    font-size: 11pt;
  }
  .text-secondary-card2 {
    padding-left: 15pt;
    /* padding: 15pt 50pt 0 20pt; */
    font-weight: 500;
    font-size: 11pt;
  }

  .separator {
    margin-top: 20pt !important;
    margin-left: 20pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }

  .subtitle {
    font-size: 14pt;
    padding-top: 5pt;
  }
}

@media (max-width: 991px) {
  .title-card {
    padding: 25pt 40pt 0 10pt;
    font-size: 16pt;
  }

  .text-secondary-card {
    margin-top: 0pt !important;
    padding: 15pt 50pt 0 10pt;
    font-weight: 500;
    font-size: 9pt;
  }

  .separator {
    padding-left: pt !important;
    margin-top: 10pt !important;
    margin-left: 10pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }

  .btn-input {
    height: 14pt;
    font-size: 9pt;
    background-color: rgb(33, 135, 172);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subtitle {
    font-size: 13pt;
  }
}

@media (max-width: 768px) {
  .separador-small {
    padding-bottom: 20pt;
  }

  .img-portada img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    padding: 0 11pt 0 11pt;
  }

  .img-portada {
    position: relative;
    height: 200pt;
  }
}
</style>
