<template>
  <div>
    <div
      class="header-content clear-filter page-header-small"
      v-bind:style="styleObject"
    >
      <div class="content-center col-12 p-0">
        <carousel-section></carousel-section>
      </div>
    </div>

    <!-- <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">¿Quienes somos?</h2>
            <h5 class="description">
              De acuerdo con la Administración Nacional Oceánica y Atmosférica, Ted, Scambos, perfumista principal del NSID, reduce la extensión máxima de hielo marino potencialmente récord en este año a una extensión de hielo baja en el Pacífico y una caída tardía en la extensión de hielo en el Mar de Barents.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/login.jpg')"
              >
                <p class="blockquote blockquote-primary">
                  "Durante el lapso del registro satelital, el hielo marino del Ártico ha disminuido significativamente, mientras que el hielo marino en la Antártida ha aumentado muy ligeramente"
                  <br />
                  <br />
                  <small>-NOAA</small>
                </p>
              </div>
              <div
                class="image-container"
                style="background-image: url('img/bg3.jpg')"
              ></div>
            </div>
            <div class="col-md-5">
              <div
                class="image-container image-right"
                style="background-image: url('img/bg1.jpg')"
              ></div>
              <h3>
                Entonces, ¿qué significa realmente el nuevo récord del nivel más bajo de hielo invernal?
              </h3>
              <p>
               El Océano Ártico se congela cada invierno y gran parte del hielo marino se descongela cada verano, y ese proceso continuará pase lo que pase con el cambio climático. Incluso si el Ártico continúa siendo una de las regiones del mundo que más rápido se calienta, siempre se sumergirá en la oscuridad polar muy fría cada invierno. Y año tras año, por todo tipo de razones naturales, hay una gran variedad del estado del hielo.




              </p>
              <p>
              Para empezar, no se deduce automáticamente que una cantidad récord de hielo se derretirá este verano. Más importante para determinar el tamaño del deshielo anual es el estado del tiempo a medida que se acerca el sol de medianoche y aumentan las temperaturas. Pero durante los más de 30 años de registros satelitales, los científicos han observado un claro patrón de declive, década tras década.
              </p>
              <p>
              El Océano Ártico se congela cada invierno y gran parte del hielo marino se descongela cada verano, y ese proceso continuará pase lo que pase con el cambio climático. Incluso si el Ártico continúa siendo una de las regiones del mundo que más rápido se calienta, siempre se sumergirá en la oscuridad polar muy fría cada invierno. Y año tras año, por todo tipo de razones naturales, hay una gran variedad del estado del hielo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- FORM DE CONTACTO -->
    <!--    <div class="section section-contact-us text-center">

      <div class="container">
        <h2 class="title">¿Quieres trabajar con nosotros?</h2>
        <p class="description">Su proyecto es muy importante para nosotros..</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="First Name..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Email Here..."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Type a message..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="primary" round block size="lg"
                >Send Message</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container-center cards col-12">
      <div class="row" style="background-color: ">
        <div class="col-3" style="background-color: ; padding: 0% 1% 0% 1%">
          <div class="card mini-card">
            <a @click="goTo('us')" class="nav-link pointer" target="_blank">
              <img src="img/IconoLaEmpresa.webp" />
              <h6 class="text-center text-card" style="">
                <strong>La empresa</strong>
              </h6>
            </a>
          </div>
        </div>

        <div class="col-3" style="background-color: ; padding: 0% 1% 0% 1%">
          <div class="card mini-card">
            <a @click="goTo('rrhh')" class="nav-link pointer" target="_blank">
              <img src="img/rh.webp" />
              <h6 class="text-center text-card" style="">
                <strong>RRHH</strong>
              </h6>
            </a>
          </div>
        </div>

        <div class="col-3" style="background-color: ; padding: 0% 1% 0% 1%">
          <div class="card mini-card">
            <a @click="goTo('notices')" class="nav-link pointer" target="_blank">
              <img src="img/IconoNoticias.webp" />
              <h6 class="text-center text-card" style="">
                <strong>Noticias</strong>
              </h6>
            </a>
          </div>
        </div>

        <div class="col-3" style="background-color: ; padding: 0% 1% 0% 1%">
          <div class="card mini-card">
            <a @click="goTo('contact')" class="nav-link pointer" target="_blank">
              <img src="img/IconoContacto.webp" />
              <h6 class="text-center text-card" style="">
                <strong>Contacto</strong>
              </h6>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Parallax } from "@/components";

import { Button, FormGroupInput } from "@/components";
import CarouselSection from "./components/CarouselSection";

export default {
  name: "home",
  components: {
    //Parallax,

    CarouselSection,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  data() {
    return {
      form: {
        firstName: "Hola",
        email: "",
        message: "",
      },
      styleObject: {
        padding: "0",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.getSizeScreen);
  },
  destroyed() {
    window.removeEventListener("resize", this.getSizeScreen);
  },
  mounted() {
    this.getSizeScreen();
  },
  methods: {
    goTo(ruta) {
      if (ruta != this.$route.name) {
        this.$router.push({ name: ruta });
      }
      //this.$refs.navbar.toggle();
    },
    getSizeScreen() {
      if (window.innerWidth < window.innerHeight) {
        if (window.innerWidth < 400 && window.innerHeight < 700) {
          this.styleObject.padding = " 10pt 5pt 10pt 5pt !important";
        } else if (window.innerWidth < 400 && window.innerHeight < 700) {
          this.styleObject.padding = " 80pt 5pt 80pt 5pt !important";
        } else if (window.innerWidth < 450 && window.innerHeight < 900) {
          this.styleObject.padding = " 100pt 5pt 120pt 5pt !important";
        } else if (window.innerWidth < 450 && window.innerHeight < 1000) {
          this.styleObject.padding = " 110pt 5pt 110pt 5pt !important";
        } else if (window.innerWidth < 550 && window.innerHeight < 800) {
          this.styleObject.padding = " 75pt 5pt 75pt 5pt !important";
        } else if (window.innerWidth < 850 && window.innerHeight < 1100) {
          this.styleObject.padding = " 170pt 70pt 170pt 70pt !important";
        } else if (window.innerWidth < 850 && window.innerHeight < 1200) {
          this.styleObject.padding = " 200pt 70pt 200pt 70pt !important";
        } else if (window.innerWidth < 970 && window.innerHeight < 1400) {
          this.styleObject.padding = " 270pt 70pt 270pt 70pt !important";
        }
      }else{
        if(window.innerWidth < 1050 && window.innerHeight < 650){
          this.styleObject.padding = ' 15pt 65pt 25pt 65pt !important'
        }else
        if(window.innerWidth < 1050 && window.innerHeight < 950){
          this.styleObject.padding = ' 80pt 70pt 125pt 70pt !important'
        }else
        if(window.innerWidth < 1170 && window.innerHeight < 950){
          this.styleObject.padding = ' 30pt 70pt 85pt 70pt !important'
        }else
        if(window.innerWidth < 1300 && window.innerHeight < 950){
          this.styleObject.padding = ' 85pt 180pt 130pt 180pt !important'
        }else
        if(window.innerWidth < 1300 && window.innerHeight < 950){
          this.styleObject.padding = ' 85pt 180pt 130pt 180pt !important'
        }else
        if(window.innerWidth < 1650 && window.innerHeight < 950){
          this.styleObject.padding = '60pt 180pt 85pt 180pt !important'
        }else
        if(window.innerWidth < 1700 && window.innerHeight < 1000){
          this.styleObject.padding = ' 70pt 180pt 110pt 180pt !important'
        }else
        if(window.innerWidth < 1950 && window.innerHeight < 1000){
          this.styleObject.padding = '60pt 180pt 85pt 180pt !important'
        }
      }

      /*console.log(window.innerWidth)
      console.log(window.innerHeight)*/
    },
  },
};
</script>

<style>
.mini-card {
  position: relative;
  top: -20px;
}
.mini-card img {
  padding: 10pt 45pt 2pt 45pt;
}
.cards {
  padding: 0 180pt 0 180pt !important;
  background-color: white;
}

.foot-prev {
  background-color: #1ea4ad !important;
  color: white;
}
.parallax-img {
  background-color: #00eeff;
  mix-blend-mode: multiply;
  filter: grayscale(10%);
}

.bg-image {
  /* Add the blur effect */
  filter: blur(3px);
  -webkit-filter: blur(3px);

  /* Full height */
  height: 100%;
  mix-blend-mode: multiply;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media (max-width: 1550px) {
}
@media (max-width: 1170px) {
  .header-content {
    padding: 0pt 90pt 30pt 90pt;
  }
  .cards {
    padding-right: 70pt !important;
    padding-left: 70pt !important;
  }
}
@media (max-width: 1024px) {
  .header-content {
    padding: 0pt 100pt 30pt 100pt;
  }
  /*-moz-animation-direction:.cards {
    padding-right: 20pt !important;
    padding-left: 20pt !important;
  }*/
}
@media (max-width: 700px) {
  .text-card {
    /* color: #1ea4ad; */
    font-size: 9px;
  }
  .header-content {
    padding: 50pt 5pt 40pt 5pt;
  }
  .mini-card img {
    padding: 10pt 0 2pt 0;
  }
  .cards {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
/*@media (max-width: 912px) and (max-height: 1368px) {
  .text-card {
    font-size: 9px;
  }
  .header-content {
    padding: 230pt 5pt 230pt 5pt;
  }
  .mini-card img {
    padding: 10pt 0 2pt 0;
  }
  .cards {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
@media (max-width: 550px) and (max-height: 1000px) {

  .header-content {
    padding: 90pt 5pt 90pt 5pt;
  }
}
@media (max-width: 550px) and (max-height: 1000px) {

  .header-content {
    padding: 90pt 5pt 90pt 5pt;
  }

}*/
.text-card {
  color: #1ea4ad;
  /* font-size: 9px; */
}

</style>
