<template>

    <div class="container mb-5">
      <div class="shadow button-container m-auto" style="background-color: white">
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="row m-auto p-0" style="background-color: ">
              <div class="col-md-10 ml-auto mr-auto p-0 text-left">
                <h3 style="color: #1ea4ad" class="title text-left">
                  Antonio Luquin S.A.C.I.F.e.I
                </h3>
                <!--  <h5 class="description">
                  According to the National Oceanic and Atmospheric
                  Administration, Ted, Scambos, NSIDClead scentist, puts the
                  potentially record low maximum sea ice extent tihs year down
                  to low ice extent in the Pacific and a late drop in ice extent
                  in the Barents Sea.
                </h5> -->
              </div>
            </div>
            <div class="separator separator-primary"></div>
            <div class="section-story-overview p-0 pb-5">
              <div class="img-duo">
        <el-carousel class="carru-us">
           <el-carousel-item style="">
            <img
              class="d-block"
              src="img/empresa-0102.png"
              size="large"
              alt="First slide"
            />           
          </el-carousel-item>
          <el-carousel-item style="">
            <img
              class="d-block"
              src="img/empresa-0102.png"
              size="large"
              alt="First slide"
            />
          </el-carousel-item>           
        </el-carousel>
              </div>





              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 p-0 parrafo">
                    <div class="parrafo-l">
                      <p>
                        Corría el año 1938,ytras haber hecho una carrera
                        impecable en la firma Grimoldi,de la cual llegóaser
                        gerente,un todavía joven Antonio Luquin inaugura su
                        primera zapatería en la calle Maipú 137 de la ciudad de
                        San Miguel de Tucumán,ala que llamó Boston.
                      </p>
                      <p>
                        El crecimiento sostenido que logra en aquellos primeros
                        años lo llevaacomprar en 1949 una importante zapatería
                        ubicada en la calle Muñecas 155 de la misma ciudad,la
                        cual ya existía desde 1908,ydecide mantener su nombre
                        original: El Sportsman,nombre que se mantiene hasta
                        nuestros días siendo sinónimo de calidad tanto en sus
                        productos como en la atención por parte de sus
                        empleados.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 p-0  parrafo">
                    <div class="parrafo-r">
                      <p>
                        El crecimiento se mantieneyen 1953 inaugura Principe una
                        casa especializada en calzado para hombres. El 27 de
                        Setiembre de 1968,con la incorporación de nuevos
                        socios,entre los cuales se encontraban los hijos de Don
                        Antonio,el Contador Oscar Antonio,la Sra.Nelly Julia, el
                        Sr. Osvaldo Meloni nace Antonio Luquin S.A.C.LFI,razón
                        social que aún hoy se mantiene tras haber cerrado 42
                        ejercicios comerciales.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pl-5 pr-5">
                <div class="col-md-4">
                  <!-- Second image on the left side of the article -->
                  <img src="img/empresa1.PNG" class="i" alt="..." />
                </div>
                <div class="col-md-4">
                  <!-- First image on the right side, above the article -->
                  <img src="img/empresa2.PNG" class="i" alt="..." />
                </div>
                <div class="col-md-4">
                  <!-- First image on the right side, above the article -->
                  <img src="img/empresa3.PNG" class="i" alt="..." />
                </div>
              </div>

              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 p-0 parrafo">
                    <div class="parrafo-l">
                      <p>
                        En 1970,con la apertura de Kebién en calle Mendoza 586
                        de SM de Tucumán,se continúa con la comercialización de
                        calzados llegado el mes de Septiembre de 1977 se
                        incorpora la comercialización en conjunto de
                        calzadoyartículos deportivos. es así que el 4/9/1977 con
                        una nota en página central del diario La Gaceta,nos
                        presentamos al público con nuestra nueva
                        actividad:DEPORTES.
                      </p>
                      <p>
                        La inauguración de la galería La Gran Via en
                        1980,principal paseo de compras de la ciudad de San
                        Miguel de Tucumán, aún en la actualidad,promueve el
                        establecimiento de Children,
                        FelinaySporting,especializándose cada una de ellas en
                        forma exclusiva en un rubro en particular:calzado para
                        niños,calzado para damasycalzado deportivo
                        respectivamente.
                      </p>
                      <p>
                        Don Antonio Luquin fallece en el año 1985,tras lo cual
                        su hijo se hace cargo de la Presidencia,cargo que
                        continúa ejerciendo hasta el presente.
                      </p>
                      <p>
                        En 1989 se inaugura la primera sucursal fuera de la
                        provincia de Tucumán,Sporting Santiago del Estero,con
                        ello se inicia una etapa de expansión de la firma por
                        todo el Noroeste Argentino.
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 p-0 parrafo">
                    <div class="parrafo-r">
                      <p>
                        Entre los años 1991y1996 la firma continúa expandiéndose
                        sustancialmente el número de sucursales, con la
                        adquisición en 1993 de varias de las zapaterías que el
                        Grupo Ruiz poseía en la provincia de
                        Tucumán,inaugurándose en ese período:Kebién Monteros,
                        Sportsman Concepción,Kebiên Aguilares,Kebién Alberdi,
                        Kebién Tafí Viejo,Sporting calle Muñecas,Maité Lizára
                        (calzado para damas),Sporting calle 25 de Mayo.
                      </p>

                      <p>
                        Por otra parte se inauguran4nuevas sucursales:Sporting
                        calle 24 de Septiembre,Sportsman Yerba Buena Shopping.
                        Sporting Shopping Terminal y Kebiên Santiago del Estero.
                      </p>
                      <p>
                        En 1998 se apuesta firmemente al desarrollo regionalyen
                        abril de ese año se inaugura Sporting de Catamarcayen
                        marzo de 2001 Sportsman Santiago del Estero.
                      </p>

                      <p>
                        La crisis de diciembre de 2001 golpes con fuerza,comoa
                        todo el país,sin embargo se inauguran tres nuevas
                        sucursales en forma casi simultánea:Sporting calle
                        Mendoza(Tucumán). Sporting SaltayNike Shop Tucumán.Con
                        esta última se inicia un novedosoyexclusivo concepto de
                        comercialización de la marca norteamericana,lo cual
                        demuestra la confianza que genera la empresa en
                        proveedores de la magnitud de Nike.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pl-5 pr-5">
                <div class="col-md-4">
                  <!-- Second image on the left side of the article -->
                  <img src="img/empresa4.PNG" class="i" alt="..." />
                </div>
                <div class="col-md-4">
                  <!-- First image on the right side, above the article -->
                  <img src="img/empresa5.PNG" class="i" alt="..." />
                </div>
                <div class="col-md-4">
                  <!-- First image on the right side, above the article -->
                  <img src="img/empresa6.PNG" class="i" alt="..." />
                </div>
              </div>
              <div class="col-12">
                <div class="row">
                  <div class="col-md-6 p-0 parrafo">
                    <div class="parrafo-l">
                      <p>
                        La post-devaluación encuentraaAntonio Luquin
                        S.A.C.I.F.L. con una excelente salud
                        económica,financieraycomercial. Una muestra de ello son
                        las aperturas de Sporting Jujuy en 2003ySporting de
                        Mendoza Plaza Shopping en Guaymallén, Mendoza en 2004.
                      </p>
                      <p>
                        Esta última se transformó en el puntapié inicial de una
                        apuesta muy fuerte,imponer un nombre de tanto prestigio
                        en una región distanteycon un mercado poco conocido,
                        apuesta que se está ganando con crecesala luz de la
                        apertura en el año 2006 de dos nuevas sucursales en la
                        provincia de Mendoza.Se implementa un cambio hacia una
                        imagen más moderna de los localesyes asi que se inaugura
                        las sucursales de:Sporting Mendoza Centro,Sporting San
                        Rafaelyen el año 2007 de Sporting San MartínyNike Shop
                        Mendoza,transformándose esta última en nuestra segunda
                        sucursal exclusiva de la marca Nike.
                      </p>
                      <p>
                        El 2007 también trae consigo la apertura de Sporting
                        Portal en el Portal Tucumán Shopping.
                      </p>
                      <p>
                        Durante los años subsiguientes se implementa el cambio
                        de imagen de los locales Sporting de las provincias de
                        Salta, Mendoza Shoppingyen la provincia de Tucumán,las
                        sucursales de calle 25 de Mayoycalle 24 de Septiembre.
                      </p>
                      <p>
                        Ese rumbo se intensifica en 2008 con la remodelación de
                        las sucursales del interior de la Provincia de Tucumán
                        que cambiaron su denominaciónaSportsman,ellas fueron:
                        Monteros,Tafi Viejo,Alberdiyla reubicación de la sucu
                        Aguilares.Asu vez,se reubicó Nike Shop Tucumányse
                        remodelaron los locales Sporting de Santiago del Esteroy
                        en Tucumán,Sporting de calle Muñecas
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6 p-0 parrafo">
                    <div class="parrafo-r">
                      <p>
                        La crisis nacionalymundial del año 2008 no desalientan
                        las ansias de crecimientoyen 2009 se llevaacabo el
                        cambio de imagen del Sporting de la Galería La Gran
                        Via,transformándose en Sporting Mujer,una apuesta
                        vanguardista única en Tucumán con mercadería exclusiva
                        para la mujer ávida en la práctica
                        deportiva.Ycontinuando con la expansión regional de la
                        firma, se produce la apertura de un nuevo local,Sporting
                        La Rioja, poniendo otro pie en una nueva provincia con
                        un concepto arquitectónico moderno,con un layout
                        innovador distinguiendo las marcas proveedoras.
                      </p>

                      <p>
                        Nike Maxi en la ciudad de Mendoza,Pioppa,indumentariay
                        accesorios para niños en la provincia de
                        Tucumán,Sporting ConcepciónySportsman en Banda del Río
                        Sali,son las más recientes inauguraciones de locales en
                        el último año.
                      </p>
                      <p>
                        Actualmente,ycon el empuje que le otorgan el contador
                        Oscar Antonio Luquin,su esposa Elsaysus dos hijos
                        profesionales,los Licenciados MarielayEsteban quienes
                        forman parte del Directorio de la firma desde hace ya
                        varios años,se sigue avanzandoapaso firme en el
                        afianzamiento de las 60 sucursales existentesyen la
                        búsqueda de nuevos mercadosyobjetivos con la misma
                        calidadyeficiencia que siempre caracterizóala Firma
                        Antonio Luquin SACIFI.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>
<script>
import { Carousel, CarouselItem } from "element-ui";

export default {
  name : 'us',
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
};
</script>
<style scoped>

.parrafo p {
  font-size: 10pt;
  text-align: left;
  font-weight: 400;
}
.parrafo-l {
  padding-top: 35pt;
  padding-right: 20pt;
  padding-left: 35pt;
}
.parrafo-r {
  padding-top: 35pt;
  padding-left: 20pt;
  padding-right: 35pt;

}
.img-duo .left {
  padding: 0 0 0 13pt;
}
.img-duo .right {
  padding: 0 13pt 0 0;
}
@media (max-width: 767px) {

  .title{
    padding-left: 20pt;
  }
  .parrafo-l {
    padding: 20pt 20pt 0 20pt;
  }
  .parrafo-r {
    padding: 0 20pt 0 20pt;
  }
  .parrafo p {
    font-size: 10pt;
    text-align: left;
    font-weight: 400;
  }

}
</style>