<template>
  <div v-bind:style="styleObject">
    <div class="container mb-5">
      <div class="shadow button-container m-auto" style="background-color: white">
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="card m-0 border-blue">
              <div class="row">
                <div class="col-md-5 img-portada">
                  
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14245.039389831478!2d-65.2029367!3d-26.7998536!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc9d53b78622d8407!2sAntonio%20Luquin%20SA!5e0!3m2!1ses!2sar!4v1660073244035!5m2!1ses!2sar"
                    style="border: 0"
                    class="maps"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div class="col-md-7">
                  <div class="title-card">Déjanos tu consulta</div>

                  <div class="col-12 pt-2 pl-4">
                    <div class="row">
                      <div class="col-md-5 col-11 pr-0 pt-3 input">
                        <input
                          placeholder="NOMBRE (requerido)"
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="col-md-6 col-11 pr-0 pt-3 input">
                        <input
                          placeholder="CORREO ELECTRÓNICO (requerido)"
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="col-11 pr-0 pt-3 input">
                        <input
                          placeholder="ASUNTO"
                          type="email"
                          class="form-control"
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                        />
                      </div>
                      <div class="col-11 pr-0 pt-3 input">
                        <textarea
                          placeholder="MENSAJE"
                          type="email"
                          class="form-control"
                          rows="4"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="pl-4 pr-4 pt-4 pb-4">
                    <div class="col-md-5 col-sm-5 bton btn-input pointer">
                      Enviar Formulario
                    </div>
                  </div>
                  <hr />
                  <div class="col-md-6 col-sm-6 col-12 pl-4">
                    <div class="row">
                      <div class="subtitle col-sm-5 col-md-5 col-3">
                        <img src="img/IconoContacto.webp" />
                      </div>
                      <div class="subtitle col-sm-7 col-md-7 col-7">
                        Información de Contacto
                      </div>
                    </div>
                  </div>
                  <div class="col-12 text-secondary-card pb-4">
                    <div class="row">
                      <div
                        class="
                          col-md-5 col-lg-5 col-sm-5 col-xl-5
                          text-capitalize
                        "
                        style=""
                      >
                        <!-- Links -->

                        <strong
                          ><p
                            style="
                              font-family: 'Montserrat', sans-serif;
                              font-size: 10pt;
                              margin: 0;
                            "
                          >
                            <i class="fas fa-home"></i> |
                            <strong> Avenida siria 2345 </strong><br />
                            <strong>San Miguel de Tucumán</strong> <br />
                            <strong>Tucumán, Argentina </strong> <br /></p
                        ></strong>
                      </div>

                      <div class="col-md-5 col-lg-5 col-sm-5 col-xl-5">
                        <p
                          style="
                            font-family: 'Montserrat', sans-serif;
                            font-size: 10pt;
                            margin: 0;
                          "
                        >
                          <i class="fas fa-envelope"></i>
                          <strong>rrhh@luquin.com.ar</strong>
                        </p>
                        <p
                          style="font-size: 10pt; margin: 0"
                        >
                          <i class="fas fa-phone"></i
                          ><strong>(0381) 4552000</strong>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="separador-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Tabs, TabPane } from "@/components";

export default {
  name: "contact",
  components: {
    // Tabs,
    // TabPane
  },
  data() {
    return {
      styleObject: {
        padding: "0",
      },
    };
  },
  mounted() {
    this.getSizeScreen();
  },
  methods: {
    getSizeScreen() {
      if (window.innerWidth < window.innerHeight) {
        if (
          window.innerWidth < 900 &&
          window.innerWidth > 800 &&
          window.innerHeight < 1400
        ) {
          this.styleObject.padding = " 80pt 5pt 100pt 5pt !important";
        } else if (
          window.innerWidth < 970 &&
          window.innerWidth > 900 &&
          window.innerHeight < 1400
        ) {
          this.styleObject.padding = " 100pt 5pt 200pt 5pt !important";
        }
      }

      //console.log(window.innerWidth)
      //console.log(window.innerHeight)
    },
  },
};
</script>
<style scoped>
.maps {
  height: 100%;
  width: 100%;
}
.border-blue {
  border-style: solid !important;
  border-width: 0 0 6pt 0 !important;
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
}
.title-card {
  padding: 50pt 50pt 0 20pt;
  font-weight: 900;
  font-size: 25pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.subtitle {
  padding: 10pt 0pt 0 0pt;
  font-weight: 900;
  font-size: 20pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.text-secondary-mini-card {
  padding: 0pt 0pt 0 0pt;
  font-weight: 500;
  font-size: 13pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.text-secondary-card {
  padding: 20pt 50pt 0 20pt;
  font-weight: 500;
  font-size: 13pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.input input {
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
}
.input textarea {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
  padding: 2pt 3pt 2pt 9pt;
  resize: vertical;
  height: 120pt;
  max-height: none;
}
.input textarea:focus {
  border-style: solid;
  border-width: 1px 1px 1px 1px;
}
.btn-input {
  background-color: rgb(33, 135, 172);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.separator {
  margin-top: 25pt !important;
  margin-left: 20pt;
  padding-top: 2pt;
  border-style: solid;
  border-width: 0 0 0 4pt;
}
.bton {
  height: 23pt;
}
.icon {
  background-color: rgb(33, 135, 172);
  border-radius: 50%;
  color: white;
  height: 40pt;
  width: 40pt;
  font-size: 18pt;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1200px) {
  .title-card {
    padding: 45pt 40pt 0 15pt;
    font-size: 18pt;
  }
  .text-secondary-card {
    margin-top: 10pt !important;
    padding: 15pt 50pt 0 20pt;
    font-weight: 500;
    font-size: 11pt;
  }
  .separator {
    margin-top: 20pt !important;
    margin-left: 20pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }
  .subtitle {
    font-size: 16pt;
    padding-top: 5pt;
  }
  .text-secondary-mini-card {
    font-weight: 500;
    font-size: 11pt;
    text-align: left;
    color: rgb(33, 135, 172);
  }
  .icon {
    height: 33pt;
    width: 33pt;
    font-size: 16pt;
  }
}
@media (max-width: 991px) {
  .title-card {
    padding: 25pt 40pt 0 10pt;
    font-size: 16pt;
  }
  .text-secondary-card {
    margin-top: 0pt !important;
    padding: 15pt 50pt 0 10pt;
    font-weight: 500;
    font-size: 9pt;
  }
  .separator {
    padding-left: pt !important;
    margin-top: 10pt !important;
    margin-left: 10pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }
  .input input {
    border-color: rgb(33, 135, 172) !important;
    border-radius: 0%;
    height: 14pt;
    font-size: 9pt;
  }
  .btn-input {
    height: 14pt;
    font-size: 9pt;
    background-color: rgb(33, 135, 172);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subtitle {
    font-size: 13pt;
  }
  .text-secondary-mini-card {
    padding-top: 5pt;
    font-weight: 500;
    font-size: 9pt;
    text-align: left;
    color: rgb(33, 135, 172);
  }
  .icon {
    height: 25pt;
    width: 25pt;
    font-size: 14pt;
  }
}
@media (max-width: 768px) {
  .separador-small {
    padding-bottom: 20pt;
  }
  .img-portada img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    padding: 0 11pt 0 11pt;
  }
  .img-portada {
    position: relative;
    height: 200pt;
  }
}
</style>
