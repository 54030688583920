<template>
    <div class="page-header" style="background-color: #252525">
        <div>
            <div class="container">
                <div class="button-logaut" v-if="!show">
                    <a class="btn btn-primary btn-round" style="background-color: #76bc21" @click="logout">Salir
                    </a>
                </div>
                <div class="separador-question"></div>
                <div slot="header" class="logo-container mb-4">
                    <img v-lazy="'img/icons/LogoTrivia.png'" alt="LogoTrivia " class="img-logo-trivia" />
                </div>
                <div class="col-md-5 ml-auto mr-auto">
                    <div class="spinner-grow text-success" role="status" v-if="show && !startGame">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div>
                        <div class="div-contaner-ranking">
                            <h3 class="text-ranking">RANKING</h3>
                            <h3 class="nombre-user-container">
                                <div>
                                    {{ user.name }}
                                </div>
                            </h3>
                            <h4 class="puntuacion-user" v-if="position > 0">
                                <div>
                                    Puesto: {{ this.position }}

                                </div>
                                <div>
                                    Puntos: {{ user.puntuation }}

                                </div>
                            </h4>
                        </div>
                        <!-- table stadisticas  -->
                        <div class="contenedor-tabla">
                            <table class="tabla-rankin table table-sm table-borderless table-striped">
                                <thead class="tabla-ranking-header">
                                    <tr>
                                        <th scope="col">Puesto</th>
                                        <th scope="col">Jugador - nro</th>
                                        <th scope="col">Puntos</th>
                                    </tr>
                                </thead>
                                <tbody class="tabla-ranking-body">
                                    <tr v-for="(item, index) in statistics" :style="textStyle(index + 1, item.id)"
                                        :key="index">
                                        <td>{{ index + 1 }}</td>
                                        <td>{{ item.name }} - {{ item.id }}</td>
                                        <td>{{ item.puntuation }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import config from "../../config";
export default {
    name: "TriviaPodium",
    bodyClass: "login-page",
    components: {},
    data() {
        return {
            game: false,
            user: {},
            statistics: [],
            show: true,
            startGame: false,
            position: 0,
            api:
                process.env.NODE_ENV === "development"
                    ? config.developmentUrl
                    : config.productionUrl,
        };
    },

    mounted() {
        this.checkInit();
    },

    methods: {
        textStyle(param, id) {
            if (param % 2 !== 0) {
                if (id == this.user.id) {
                    this.position = param;
                    return " background-color: #494848; color: #76bc21;";
                }
                return " background-color: #494848;";
            } else {
                if (id == this.user.id) {
                    this.position = param;
                    return "color: #76bc21;";
                }
            }
        },
        checkInit() {
            if (this.user != null) {
                this.getPlayers();
            }
        },
        getUserLocalStorage() {
            let user = JSON.parse(localStorage.getItem("user"));
            if (user !== null) {
                this.user = user.user;
                this.startGame = user.game;
            }
        },
        getPlayers() {
            // fetch("http://192.168.1.18:8080/data.json").then(res=>res.json())
            fetch(`${this.api}api/trivia/getPlayers`)
                .then((res) => res.json())
                .then((result) => {
                    this.statistics = result.user;
                    this.show = false;
                    this.getUserLocalStorage()
                });
        },
        logout() {
            localStorage.removeItem("user");
            this.$router.replace('/trivia');
        },
    },
};
</script>
<style scoped>
.button-logaut {
    display: flex;

    margin-bottom: 10px;

    justify-content: flex-end;
}

.separador-question {
    margin-bottom: 60px;
}

.div-contaner-ranking {
    padding-left: 50px;
    padding-right: 50px;
}

.nombre-user-container {
    background-color: #76bc21;
    border-radius: 20px;
    text-align: center;
    font-weight: 700;
}

.text-ranking {
    text-align: center;
    font-weight: 800;
}

.puntuacion-user {
    color: #76bc21;
    font-weight: 800;
    padding-inline: 2rem;
    display: flex;
    justify-content: space-between;
}

/* tabla */
.tabla-rankin {
    color: #ffffff;
    text-align: center;
}

.tabla-ranking-header th {
    font-weight: 900;
}

.tabla-ranking-body th {
    font-weight: 400;
}

/* imagen */
.contenedor-tabla {
    height: 400pt;
    overflow: auto;
}

@media (max-width: 768px) {
    .img-logo-trivia {
        padding: 50px;
    }

    .separador-question {
        margin-bottom: 0px;
    }

    .puntuacion-user {
        font-size: 1rem;
        gap: 1rem;
    }

}

@media (min-width: 768px) {
    .img-logo-trivia {
        width: 50%;
        height: 50%;
    }

    .contenedor-tabla::-webkit-scrollbar-thumb {
        background-color: #252525;
        /* color del thumb del scrollbar */
    }

    .contenedor-tabla::-webkit-scrollbar {
        width: 5px;
        /* ajusta el ancho del scrollbar */
    }

    .puntuacion-user {
        font-size: 1rem;
    }

}
</style>
