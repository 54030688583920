<template>
  <div class="page-header" style="background-color: #252525">
    <div class="">
      <div class="container">
        <div slot="header" class="logo-container mb-4">
          <img
            v-lazy="'img/icons/LogoTrivia.png'"
            alt="LogoTrivia "
            class="img-logo-trivia"
          />
        </div>
        <div class="col-md-5 ml-auto mr-auto">
          <card type="login" plain>
            <div class="spinner-grow text-success" role="status" v-if="show">
              <span class="sr-only">Loading...</span>
            </div>

            <div v-else>
              <Alert type="warning" v-if="statusAlert">{{ msjError }}</Alert>
              <label for="Nombre">
                ingrese sus datos para comenzar el juego
              </label>
              <fg-input
                id="Nombre"
                class="no-border input-lg"
                addon-left-icon="now-ui-icons users_circle-08"
                placeholder="Nombre completo"
                v-model="nombre"
                required
              >
              </fg-input>

              <fg-input
                class="no-border input-lg"
                addon-left-icon="now-ui-icons ui-1_email-85"
                placeholder="Email"
                type="email"
                v-model="email"
                required
              >
              </fg-input>
            </div>

            <template v-if="!show" slot="raw-content">
              <div class="card-footer text-center">
                <a
                  class="btn btn-primary btn-round btn-lg btn-block"
                  style="background-color: #76bc21"
                  @click="sendDataUser"
                  >Jugar</a
                >
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, FormGroupInput, Alert } from "@/components";
import config from "../../../../config";

export default {
  name: "login-page",
  bodyClass: "login-page",

  components: {
    Card,
    [FormGroupInput.name]: FormGroupInput,
    Alert,
  },

  data() {
    return {
      nombre: "",
      email: "",
      statusAlert: false,
      show: false,
      msjError: "",
      url:
        process.env.NODE_ENV === "development"
          ? config.developmentUrl
          : config.productionUrl,
    };
  },
  mounted() {},

  methods: {
    sendDataUser() {
      if (this.nombre.trim() == "" || this.email.trim() == "") {
        this.msjError = "Todo los campos son obligatorio";
        this.statusAlert = true;
        setTimeout(() => {
          this.statusAlert = false;
        }, 3000);
      } else {
        let isValidEmail = this.validateEmail(this.email);

        if (!isValidEmail) {
          this.msjError =
            'Ingresa una dirección de correo válida, como "nombre@ejemplo.com"';
          this.statusAlert = true;
          setTimeout(() => {
            this.statusAlert = false;
          }, 3000);
        } else {
          // loginPlayer
          this.show = true;
          let data = {
            name: this.nombre,
            email: this.email,
          };
          fetch(`${this.url}api/trivia/loginPlayer`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(data),
          })
            .then((res) => res.json())
            .then((result) => {
              this.$emit("statusChange", result);
            })

            .catch((err) => {})
            .finally(() => {
              this.show = false;
            });
        }
      }
    },

    validateEmail(email) {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return regex.test(email);
    },
  },
};
</script>
<style scoped>
.iconAlquimia {
  border-radius: 20%;
}
.img-logo-trivia {
  padding: 50px;
}

/* imagen */

@media (max-width: 768px) {
  .contenedor-tabla {
    height: 400pt;
    overflow: scroll;
  }
  .img-logo-trivia {
    padding: 50px;
  }
}

@media (min-width: 768px) {
  .img-logo-trivia {
    width: 50%;
    height: 50%;
  }
}
</style>
