import Vue from 'vue';
import Router from 'vue-router';
import Index from './pages/Index1.vue';

import Contact from './pages/Contact.vue';
import Us from './pages/Us.vue';
import Alquimia from './pages/Alquimia.vue';
import Rrhh from './pages/Rrhh.vue';
import Postulation from './pages/Postulation.vue';
import Notices from './pages/Notices.vue';
import LayoutPage from './layout/LayoutPage.vue';

import Trivia from './pages/Trivia.vue';
// import Branch from './pages/Branch.vue';
import TriviaPodium from './pages/TriviaPodium.vue';

import MainNavbar from './layout/MainNavbar.vue';
import MainFooter from './layout/MainFooter.vue';

Vue.use(Router);

export default new Router({
  mode: "history",
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      components: { default: LayoutPage, header: MainNavbar, footer: MainFooter },
      props: {
        header: { colorOnScroll: 30 },
        footer: { backgroundColor: 'black' }
      },
      redirect: 'home',
      children: [
        {
          path: 'us',
          name: 'us',
          //components: { default: Us, header: MainNavbar, footer: MainFooter },
          component: Us,
        },
        {
          path: 'home',
          name: 'home',
          //components: { default: Us, header: MainNavbar, footer: MainFooter },
          component: Index,
        },
        {
          path: 'alquimia',
          name: 'alquimia',
          component:  Alquimia,
        },
        /* {
          path: 'sucursales',
          name: 'sucursales',
          component:  Branch,
        }, */
        {
          path: 'rrhh',
          name: 'rrhh',
          component: Rrhh,
        },
        {
          path: 'postulation',
          name: 'postulation',
          props: true,
          component: Postulation,
        },
        {
          path: 'notices',
          name: 'notices',
          component: Notices,
        },
        /*{
          path: 'notice/:id',
          name: 'notice',
          component: Notice,   
        },*/
        {
          path: 'contact',
          name: 'contact',
          component: Contact,
        },
        {
          path: 'trivia',
          name: 'trivia',
          component: Trivia,
        },
        {
          path: 'trivia-podium',
          name: 'trivia-podium',
          component: TriviaPodium,
        },
      ]
    },
    /* {
      path: '/trivia',
      name: 'trivia',
      components: Contact,
      props: {
        header: { colorOnScroll: 300 },
        footer: { backgroundColor: 'black' }
      }
    }, */


  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
