<template>
    <div class="col-md-6 col-sm-12 p-0 p-5">
      <div class="row loading-skeleton ">
        <div class="col-12 p-0">
          <img
            src="img/notice-2.PNG"
            style="width: 100%; height: 100%"
            alt="..."
          />
        </div>
        <div class="col-12 date-notice">Julio de 2021</div>
        <div class="col-12 p-0 notice">
          <div class="subtitle">Nuevos locales adidas</div>
          <div class="text-secondary-mini-card">
            Encabezado noticia: Lorem ipsum dolor sit amet consectetur
            adipisicing elit. quo deleniti at ea quasi.
          </div>
          <div class="col-12">
            <div class="row">
              <div class="bton btn-input col-md-4 col-sm-4 pointer mt-2">
                Leer más
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>
<script>
export default {
    name : 'SkeletonNotice'
}
</script>
<style scoped>
.loading-skeleton h1,
.loading-skeleton h2,
.loading-skeleton h3,
.loading-skeleton h4,
.loading-skeleton h5,
.loading-skeleton h6,
.loading-skeleton p,
.loading-skeleton li,
.loading-skeleton .btn,
.loading-skeleton label,
.loading-skeleton .form-control,
.loading-skeleton .date-notice,
.loading-skeleton .text-secondary-mini-card,
.loading-skeleton .bton {
  color: transparent !important;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee !important;
  border-color: #eee;
}
.loading-skeleton .subtitle {
  visibility: hidden;
}
.loading-skeleton h1::placeholder,
.loading-skeleton h2::placeholder,
.loading-skeleton h3::placeholder,
.loading-skeleton h4::placeholder,
.loading-skeleton h5::placeholder,
.loading-skeleton h6::placeholder,
.loading-skeleton p::placeholder,
.loading-skeleton li::placeholder,
.loading-skeleton .btn::placeholder,
.loading-skeleton label::placeholder,
.loading-skeleton .form-control::placeholder {
  color: transparent;
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;
}
.loading-skeleton img {
  filter: grayscale(100) contrast(0%) brightness(1.8);
}
</style>