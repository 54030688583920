<template>
  <div class="" id="">
    <div class="row justify-content">
      <div class="col-12">
        <el-carousel>

           <el-carousel-item style="">
            <a href="https://www.sporting.com.ar/"  target="_blank" rel="noopener noreferrer" >
            <img
              class= "d-block"
              src="img/Slider-03.webp"
              size="large"
              alt="First slide"
            />
            </a>

          </el-carousel-item>
           
          <el-carousel-item style="">
            <a href="https://www.wokerbysporting.com.ar/" target="_blank" rel="noopener noreferrer">
            <img
              class="d-block"
              src="img/Slider-04.webp"
              size="large"
              alt="First slide"
            />
            </a>

          </el-carousel-item>

           <el-carousel-item style="">
            <img
              class="d-block"
              src="img/Slider-01.webp"
              size="large"
              alt="First slide"
            />

           
          </el-carousel-item>

  
          <el-carousel-item style="">
            <img
              class="d-block"
              src="img/Slider-02.webp"
              size="large"
              alt="First slide"
            />
          </el-carousel-item>

           <el-carousel-item style="">
            <a href="https://www.tuszapatos.com.ar/" target="_blank" rel="noopener noreferrer">
            <img
              class="d-block"
              src="img/Slider-05.webp"
              size="large"
              alt="First slide"
            />
            </a>

          </el-carousel-item>
 
           
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, CarouselItem } from "element-ui";

export default {
  components: {
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
  },
};
</script>
<style>
</style>
