<template>
  <div id="quiz">
    <div>
      <p class="txt-preguntas">{{ questionText }}</p>
      <!-- <div v-for="(item, index) in listChoices" :key="index">  
      
          <button  @click="checkChoice(item)" >{{item}}</button>
      
      </div> -->
      <!-- {{ listChoices }}  -->

      <div class="row">
        <div
          class="col-sm-12 mb-1"
          v-for="(item, index) in listChoices"
          :key="index"
        >
          <button
            type="button"
            :id="item"
            @click="checkChoice(item)"
            class="btn btn-neutral btn-lg btn-block boton-radio"
          >
            {{ item }}
          </button>
        </div>
      </div>

      <div class="progress progress-container">
        <div
          class="progress-bar bg-success progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          style="width: 100%"
          id="temporizador"
        ></div>
      </div>
      <div class="count-question"> {{ progress }}</div>
    </div>


  </div>
</template>
<script>
import config from "../../../../config";

// @ts-check
export default {
  name: "Questions",
  bodyClass: "login-page",
  props: ["questions", "userId"],
  components: {},
  data() {
    return {
      questionText: "",
      listChoices: [],
      progress: "",
      questionIndex: 0,
      score: 0,
      size: 0,
      listQuestions: null,
      answer: "",
      endCount: false,
      correctAnswer: false,
      responseTime: 0,
      questionId: null,
      api:
        process.env.NODE_ENV === "development"
          ? config.developmentUrl
          : config.productionUrl,
    };
  },
  mounted() {
    this.createQuestion();
    this.countDown();
  },

  methods: {
    saveValueTimePlayer(time) {
      let data = {
        player_id: this.userId,
        question_id: this.questionId,
        value_time: time,
        value_question: false,
      };
      fetch(`${this.api}api/trivia/savePlayerHasQuestion`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        // @ts-ignore
        .then((data) => {})
        // @ts-ignore
        .catch((err) => {
          // console.log(err);
        });
    },

    updateValueQuestion(time) {
      let data = {
        player_id: this.userId,
        question_id: this.questionId,
        value_time: time,
        value_question: true,
      };
      fetch(`${this.api}api/trivia/updateValueQuestion`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        // @ts-ignore
        .then((data) => {
          // console.log(data);
        })
        // @ts-ignore
        .catch((err) => {
          // console.log(err);
        });
    },

    countDown() {
      let tiempoInicioPregunta = Date.now();
      let progressBar = document.getElementById("temporizador");
      let valorActual = 100;

      if (progressBar !== null) {
        progressBar.style.width = valorActual + "%";
      }

      let intervalo = setInterval(() => {
        if (this.endCount) {
          clearInterval(intervalo);
          if (this.correctAnswer) {
            let tiempoDeRespuesta = Date.now() - tiempoInicioPregunta;
            // console.log("Tiempo de respuesta:", tiempoDeRespuesta, "ms");

            this.responseTime += tiempoDeRespuesta;
            // this.saveValueTimePlayer(tiempoDeRespuesta);
            this.updateValueQuestion(tiempoDeRespuesta);
          }
        } else {
          if (progressBar !== null) {
            if (this.size == this.questionIndex) {
              clearInterval(intervalo);
            } else {
              valorActual -= 10;
              progressBar.style.width = valorActual + "%";

              if (valorActual == 0) {
                valorActual = 100;
                progressBar.style.width = valorActual + "%";
                this.questionIndex++;
                this.viewQuestion();
                tiempoInicioPregunta = Date.now();
              }
            }
          }
        }
      }, 1000);
    },

    checkChoice(value) {
      let buttons = document.querySelectorAll("button");

      buttons.forEach(
        (button) => (
          (button.disabled = true),
          ((button.style.color = "#fff"), (button.style.color = "#252525"))
        )
      );

      this.endCount = true;

      if (value === this.answer) {
        this.correctAnswer = true;

        let btn = document.getElementById(value);
        if (btn !== null) {
          btn.style.backgroundColor = "#76BC21";
        }

        this.questionIndex++;
        this.score += 10;

        setTimeout(() => {
          if (btn !== null) {
            btn.style.backgroundColor = "#FFFFFF";
            btn.style.color = "#252525";
          }

          this.viewQuestion();
          this.endCount = false;
          this.correctAnswer = false;
          this.countDown();
          buttons.forEach((button) => (button.disabled = false));
        }, 3000);
      } else {
        let btn = document.getElementById(value);
        let btnOk = document.getElementById(this.answer);

        if (btn !== null && btnOk !== null) {
          btn.style.backgroundColor = "#FF0000";
          btnOk.style.backgroundColor = "#76BC21";
        }
        this.questionIndex++;

        setTimeout(() => {
          if (btn !== null && btnOk !== null) {
            btn.style.backgroundColor = "#FFFFFF";
            btn.style.color = "#252525";
            btnOk.style.backgroundColor = "#FFFFFF";
            btnOk.style.color = "#252525";
          }
          this.viewQuestion();
          this.endCount = false;
          this.countDown();
          buttons.forEach((button) => (button.disabled = false));
        }, 3000);
      }
    },

    viewQuestion() {
      if (this.size === this.questionIndex) {
        this.score = this.score * this.responseTime;
        this.goToStatistics();
      } else {
        let data = this.questions[this.questionIndex];
        this.questionId = data.id;
        this.saveValueTimePlayer(0);
        this.listChoices = [];
        this.answer = "";
        this.questionText = data.question;
        this.listChoices = data.choices;
        this.answer = data.answer;
        this.progress = `Pregunta ${this.questionIndex + 1} de ${this.size}`;
      }
    },

    createQuestion() {


      this.listQuestions = this.questions;
      this.size = this.questions.length;
      this.viewQuestion();
    },
    goToStatistics() {
      this.$emit("endGame", true);
    },
  },
};
</script>
<style scoped>
.colorRed {
  background-color: red;
}
.colorGreen {
  background-color: green;
}

.txt-preguntas {
  text-align: center;
  font-weight: 700;
  color: #ffffff;
  font-size: 1rem;
}
.boton-radio {
  color: #252525;
  text-align: center;
  font-weight: 600;
  border-radius: 10px;
}
.progress-container {
  height: 10px;
}
.count-question{
  font-weight: 600;
  text-align: center;
  margin-top: 10px;

}
</style>
