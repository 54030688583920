<template>

  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      
      <!-- Section: Social media -->
      <!--  -->
      <!-- Section: Social media -->

      <!-- Section: Links  -->
      
      <!-- Section: Links  -->
      <!-- <nav>
        <ul>
          <li>
            <a href="https://www.creative-tim.com">
              Creative Tim
            </a>
          </li>
          <li>
            <a href="https:///presentation.creative-tim.com">
              About Us
            </a>
          </li>
          <li>
            <a href="https:///blog.creative-tim.com">
              Blog
            </a>
          </li>
        </ul>
      </nav> -->
      <div class="copyright">
        &copy; {{ year }}, Designed by
        <a href="#" target="_blank" rel="noopener"
          >Antonio Luquin</a
        >. Coded by
        <a href="#" target="_blank" rel="noopener"
          >Desarrollo Antonio Luquin</a
        >
       <!--  and
        <a href="https://www.creative-tim.com" target="_blank" rel="noopener"
          >Creative Tim</a
        >. -->
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
