<template>
  <div>
    <div class="container mb-5">
      <div
        class="shadow button-container m-auto"
        style="background-color: white"
      >
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="card m-0 border-blue">
              <div class="row">
                <div class="col-md-5 img-portada">
                  <!-- Second image on the left side of the article -->
                  <img src="img/noticias.PNG" class="i" alt="..." />
                </div>
                <div class="col-md-7">
                  <div class="title-card">
                    Enterate de las
                    <b style="font-weight: 1100">últimas noticias</b> de la
                    empresa
                  </div>
                </div>
              </div>
              <div class="separador-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5">
      <div
        class="shadow button-container m-auto"
        style="background-color: white"
      >
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="card m-0 border-blue">
              <div class="row">
                <div class="col-md-12">
                  <div class="col-12 pt-0 pl-5 pr-5 mb-1">
                    <div class="row">
                      <SkeletonNotice v-if="skeleton" />
                      <SkeletonNotice v-if="skeleton" />
                      <div
                        v-for="(item, index) in news"
                        :key="index"
                        class="card-notice col-md-6 col-sm-12 p-0 p-5"
                      >
                        <div class="row">
                          <div class="col-12 p-0 container-img">
                            <img
                              style="
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                overflow: hidden;
                              "
                              :src="url + item.files[0].path"
                              alt="..."
                            />
                          </div>
                          <div class="col-12 date-notice">
                            
                          </div>
                          <div class="col-12 p-0 notice">
                            <div class="subtitle">{{ item.title }}</div>
                            <div class="text-secondary-mini-card">
                              {{ item.subtitle }}
                            </div>
                            <div class="col-12">
                              <div class="row">
                                <!--div
                                  @click="$router.push({
                                      name: 'notice', 
                                      params: { id: item.id }
                                  });"
                                  class="
                                    bton
                                    btn-input
                                    col-md-4 col-sm-4
                                    pointer
                                    mt-2
                                  "
                                >
                                  Leer más
                                </div -->
                                <!--div class="col-8 icon-comment" align="right">
                                  <i class="fas fa-comments"></i>
                                </div-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SkeletonNotice from "../pages/components/SkeletonNotice";
import config from "../../config"

export default {
  name: "notices",
  data() {
    return {
      news: [],
      loading: false,
      skeleton: true,
      url : process.env.NODE_ENV === "development" ? config.developmentUrl : config.productionUrl
    };
  },
  components: {
    SkeletonNotice,
  },
  async mounted() {
    //this.checkProduction();
    const response = await fetch(`${this.url}api/web-luquin/getNews`);
    const data = await response.json();
    this.news = data;
    this.skeleton = false;
  },
  methods: {
    checkProduction() {
      this.url  = process.env.NODE_ENV === "development" ? config.developmentUrl : config.productionUrl
    },
  },
};
</script>
<style scoped>
.container-img {
  width: 100%;
  height: 200pt;
}
.card-notice {
  padding: 30pt 20pt 20pt 20pt !important;
}
.icon-comment {
  font-size: 15pt;
  margin-top: 6pt;
  display: flex;
  align-items: center;
  justify-content: right;
  color: rgb(33, 135, 172);
}
.date-notice {
  padding: 10pt 0 10pt 0;
  text-align: left;
  font-weight: 600;
  color: rgb(33, 135, 172);
}
.notice {
  padding-bottom: 10pt !important;
  border-style: solid;
  border-width: 3pt 0 3pt 0;
  border-color: rgb(33, 135, 172) !important;
}
.border-blue {
  border-style: solid !important;
  border-width: 0 0 6pt 0 !important;
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
}
.title-card {
  padding: 38pt 50pt 0 20pt;
  font-weight: 500;
  font-size: 28pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.subtitle {
  padding: 10pt 0pt 0 0pt;
  font-weight: 900;
  font-size: 20pt;
  text-align: left;
  color: rgb(33, 135, 172);
  height: 70pt;
}
.text-secondary-mini-card {
  padding: 0pt 0pt 0 0pt;
  font-weight: 500;
  font-size: 13pt;
  text-align: left;
  color: rgb(33, 135, 172);
  height: 55pt;
}
.text-secondary-card {
  padding: 20pt 50pt 0 20pt;
  font-weight: 500;
  font-size: 13pt;
  text-align: left;
  color: rgb(33, 135, 172);
}
.input input {
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
}
.btn-input {
  background-color: rgb(33, 135, 172);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.separator {
  margin-top: 25pt !important;
  margin-left: 20pt;
  padding-top: 2pt;
  border-style: solid;
  border-width: 0 0 0 4pt;
}
.bton {
  height: 23pt;
}
.icon {
  background-color: rgb(33, 135, 172);
  border-radius: 50%;
  color: white;
  height: 40pt;
  width: 40pt;
  font-size: 18pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .container-img {
    height: 180pt;
  }
  .title-card {
    padding: 45pt 40pt 0 15pt;
    font-size: 18pt;
  }
  .text-secondary-card {
    margin-top: 10pt !important;
    padding: 15pt 50pt 0 20pt;
    font-weight: 500;
    font-size: 11pt;
  }
  .separator {
    margin-top: 20pt !important;
    margin-left: 20pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }
  .subtitle {
    font-size: 16pt;
    padding-top: 5pt;
    height: 53pt;
  }
  .text-secondary-mini-card {
    font-weight: 500;
    font-size: 11pt;
    text-align: left;
    color: rgb(33, 135, 172);
    height: 50pt;
  }
  .icon {
    height: 33pt;
    width: 33pt;
    font-size: 16pt;
  }
}
@media (max-width: 991px) {
  .container-img {
    height: 145pt;
  }
  .title-card {
    padding: 25pt 40pt 0 10pt;
    font-size: 16pt;
  }
  .text-secondary-card {
    margin-top: 0pt !important;
    padding: 15pt 50pt 0 10pt;
    font-weight: 500;
    font-size: 9pt;
  }
  .separator {
    padding-left: pt !important;
    margin-top: 10pt !important;
    margin-left: 10pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }
  .input input {
    border-color: rgb(33, 135, 172) !important;
    border-radius: 0%;
    height: 14pt;
    font-size: 9pt;
  }
  .btn-input {
    height: 14pt;
    font-size: 9pt;
    background-color: rgb(33, 135, 172);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .subtitle {
    font-size: 13pt;
    height: 40pt;
  }
  .text-secondary-mini-card {
    padding-top: 5pt;
    font-weight: 500;
    font-size: 9pt;
    text-align: left;
    color: rgb(33, 135, 172);
    height: 45pt;
  }
  .card-notice {
    padding: 20pt 20pt 15pt 20pt !important;
  }
  .icon {
    height: 25pt;
    width: 25pt;
    font-size: 14pt;
  }
}
@media (max-width: 768px) {
  .container-img {
    height: 185pt;
  }
  .subtitle {
    height: 40pt;
  }
  .text-secondary-mini-card {
    height: 30pt;
  }
  .separador-small {
    padding-bottom: 20pt;
  }
  .img-portada img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    padding: 0 11pt 0 11pt;
  }
  .img-portada {
    position: relative;
    height: 200pt;
  }
  .card-notice {
    padding: 20pt 10pt 15pt 10pt !important;
  }
}
@media (max-width: 440px) {
  .container-img {
    height: 150pt;
  }
  .subtitle {
    height: 40pt;
  }
  .card-notice {
    padding: 20pt 10pt 0 10pt !important;
  }
  .text-secondary-mini-card {
    height: 45pt;
  }
}
</style>