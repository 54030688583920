<template>
  <navbar
    ref="navbar"
    position="fixed"
    
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    style="padding: 0% 0 1% 0;"
  >
    <template style="background-color: green">
      <!-- <router-link v-popover:popover1 class="navbar-brand" to="/"> -->

      <img
        @click="home()"
        class="pointer"
        align="left"
        style="width: 200px"
        src="img/LogoAntonioLuquin.webp"
        alt=""
      />
      <!-- </router-link> -->
      <!-- <el-popover
        ref="popover1"
        popper-class="popover"
        placement="bottom"
        width="200"
        trigger="hover"
      >
        <div class="popover-body">Sitio desarrollado por Antonio Luquin</div>
      </el-popover> -->
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <a @click="goTo('us')" class="nav-link pointer" target="_blank">
          <i class="now-ui-icons"></i>
          La Empresa
        </a>
      </li>

      <li class="nav-item">
        <a @click="goTo('alquimia')" class="nav-link pointer" target="_blank">
          <i class="now-ui-icons"></i>
          <p>Alquimia</p>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a @click="goTo('sucursales')" class="nav-link pointer" target="_blank">
          <i class="now-ui-icons"></i>
          <p>Sucursales</p>
        </a>
      </li> -->
      <li class="nav-item">
        <a @click="goTo('rrhh')" class="nav-link pointer" target="_blank">
          <i class="now-ui-icons"></i>
          <p>RRHH</p>
        </a>
      </li>
      <li class="nav-item">
        <a @click="goTo('notices')" class="nav-link pointer" target="_blank">
          <i class="now-ui-icons"></i>
          <p>Noticias</p>
        </a>
      </li>
      <li class="nav-item">
        <!-- <nav-link to="/contact">
          <i class="text-white"></i> Contacto
        </nav-link> -->
        <a @click="goTo('contact')" class="nav-link pointer" target="_blank">
          <i class="now-ui-icons"></i>
          <p>Contacto</p>
        </a>
      </li>


    <!--   <li class="nav-item">
        <nav-link to="/contact">
          <i class="text-white"></i> Contacto
        </nav-link>
        <a @click="goTo('trivia')" class="nav-link pointer" target="_blank">
          <i class="now-ui-icons"></i>
          <p>trivia</p>
        </a>
      </li> -->

    </template>
  </navbar>
</template>

<script>
import { Navbar, NavLink } from "@/components";
import { Popover } from "element-ui";
export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    // DropDown,
    Navbar,
    // NavLink,
    [Popover.name]: Popover,
  },
  methods: {
    home(){
      this.$route.name != 'home' ? this.$router.push({ name: 'home' }) : false
    },
    goTo(ruta) {
      if (ruta != this.$route.name) {
        this.$router.push({ name: ruta });
        //this.Navbar.toggle();
        this.$refs.navbar.toggle();
      } else {
        this.$refs.navbar.toggle();
      }
    },
  },
};
</script>

<style >
.pointer {
  cursor: pointer;
}
</style>
