<template>
  <div id="app" style="height: 100% !important;" >
    <router-view v-if="currentRoute !== '/trivia' && currentRoute !== '/trivia-podium'" name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view  v-if="currentRoute !== '/trivia' && currentRoute !== '/trivia-podium'" name="footer" />
  </div>
</template>
<script>
export default {
  computed: {
    currentRoute() {
      return this.$route.path;
    }
  }
};
</script>

<style>
body,
html {
  margin: 0 !important;
  padding: 0 !important;
  width: 100% !important;
}
body {
  min-width: fit-content;
}
.img-duo {
  padding-left: 30pt !important;
  padding-right: 30pt !important;
}
.logo-nav-2 {
  display: none;
}

@media (max-width: 990px) {
  .logo-nav-2 {
    display: block;
  }
}
@media (max-width: 767px) {
  .el-carousel__arrow {
    top: 25% !important;
  }
  .el-carousel {
    height: 140pt !important;
  }
  .img-duo {
    padding-left: 5pt !important;
    padding-right: 5pt !important;
  }
}
</style>
