<template>
  <div>
    <Login @statusChange="statusChange" v-if="!loggedInStatus" />

    <Statistics
      :dataUser="loggedInUserData"
      :dataQuestions="questions"
      @logout="logout"
      v-else
    />
  </div>
</template>
<script>
import Login from "./components/trivia/Login.vue";
import Statistics from "./components/trivia/Statistic.vue";
import config from "../../config"

export default {
  name: "trivia",
  components: { Login, Statistics },

  data() {
    return {
      loggedInStatus: false,
      loggedInUserData: null,
      api:process.env.NODE_ENV === "development" ? config.developmentUrl : config.productionUrl,
      questions: null,
    };
  },
  created() {
    this.getQuestions();
  },

  mounted() {
    this.getUserLocalStorage();
  },
  methods: {
    logout() {
      this.loggedInStatus = !this.loggedInStatus;
    },
    statusChange(dataUser) {
      this.loggedInStatus = !this.loggedInStatus;
      this.loggedInUserData = dataUser;
      this.savaUserLocalStorage(dataUser);
    },

    savaUserLocalStorage(dataUser) {
      localStorage.setItem("user", JSON.stringify(dataUser));
    },

    getQuestions() {
      // fetch("http://192.168.1.18:8080/data.json").then(res=>res.json())
      fetch(`${this.api}api/trivia/getChoices`)
        .then((res) => res.json())
        .then((result) => {
          this.questions = result[0];
        });
    },
    getUserLocalStorage() {
      let user = localStorage.getItem("user");
      if (user !== null) {
        this.loggedInStatus = true;
      }
    },
  },
};
</script>
<style></style>
