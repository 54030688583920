<template>
  <div>
    <div class="container mb-5">
      <div class="shadow button-container m-auto" style="background-color: white">
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="card m-0 border-blue">
              <div class="row">

                <div class="d-md-none col-sm-12 pt-3">
                  <center>
                    <img src="img/alquimia/logo_alquimia.webp" class="" alt="..." width="40%" />
                  </center>
                </div>
                <div class="d-md-none col-sm-12 ">
                  <center>
                    <img src="img/alquimia/al_logo.webp" class="" alt="..." width="50%" />
                  </center>
                </div>

                <div
                  class="d-none d-md-block col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-1 offset-lg-1 offset-xl-1 offset-xxl-1img-portada pt-4">
                  <img src="img/alquimia/logo_alquimia.webp" class="" alt="..." width="70%" />
                </div>
                <div class="d-none d-md-block col-md-7 col-lg-7 col-xl-7 col-xxl-7">
                  <center>
                    <img src="img/alquimia/al_logo.webp" class="" alt="..." width="50%" />
                  </center>
                </div>

              </div>
              <div class="separador-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container mb-5">
      <div class="shadow button-container m-auto" style="background-color: white">
        <div class="section p-0" style="background-color: ">
          <div class="container p-0" style="background-color: ">
            <div class="card m-0 border-blue">
              <div class="row">
                <div class="col-md-12">
                  <div class="col-12 pt-0 pl-5 pr-5 mb-1">
                    <div class="row">
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6pb-0 col-xl-6 pb-0 mb-0">
                        <p class="title-card"><b>Manifiesto</b></p>
                      </div>
                      <div class="col-12 col-sm-6 col-md-6 col-lg-6pb-0 col-xl-6 mb-0">
                        <p class="text-secondary-card pt-5 mt-4 pb-0 mb-0" style="text-align: right;"><b>Octubre
                            2022</b></p>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <p class="text-secondary-card"><b>A vos que formas parte de la empresa:</b></p>
                        <p class="text-secondary-card">Durante la pandemia iniciada en marzo del 2020 las empresas en
                          general tuvieron una necesidad de acelerar la adaptación a un mundo nuevo y mucho más
                          desafiante. Los avances tecnológicos proyectados necesitaron tomar una velocidad de cambios
                          nunca vista en la historia.</p>
                        <p class="text-secondary-card">Es por eso que en la empresa, ya habiendo comenzado con ecommerce
                          en el 2014, debimos incorporar una serie de herramientas que nos permitieron avanzar y estar
                          como estamos hoy. Herramientas que, día que pasa, día que sale algo nuevo, algo superior.</p>
                        <p class="text-secondary-card">Dados estos temas, nos vemos en la necesidad de encarar una
                          Transformación Digital Integral de la Empresa, diseñando una Estrategia clara para los
                          próximos años.</p>
                        <p class="text-secondary-card">De ahí nace <b>Alquimia.</b></p>
                        <p class="text-secondary-card">Le damos el nombre de Alquimia al proceso de Transformación
                          Digital y Estratégica que llevaremos a cabo en Antonio Luquin basado en tres pilares:</p>
                      </div>
                    </div>

                    <!-- <div class="row" style="padding: 20pt 50pt 0 20pt;">
                      <div class="col-4">
                        <div class="first-drawing">
                          <p style="text-align: center;"><b>Experiencia del Cliente</b></p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="first-drawing">
                          <p style="text-align: center;"><b>Digitalización e Innovación</b></p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="first-drawing">
                          <p style="text-align: center;"><b>Trabajo colaborativo</b></p>
                        </div>
                      </div>
                    </div> -->

                    <div class="row" style="padding: 0 50pt 0 20pt;">
                      <div class="col-4 p-0">
                        <div class="first-drawing">
                          <img src="img/alquimia/pilar1.webp" class="" alt="..." />
                        </div>
                      </div>
                      <div class="col-4 p-0">
                        <div class="first-drawing">
                          <img src="img/alquimia/pilar2.webp" class="" alt="..." />
                        </div>
                      </div>
                      <div class="col-4 p-0">
                        <div class="first-drawing">
                          <img src="img/alquimia/pilar3.webp" class="" alt="..." />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <p class="text-secondary-card">Transformación Digital se define como el cambio cultural que
                          acompaña al uso de la tecnología para mejorar radicalmente el rendimiento o el alcance de las
                          empresas.</p>
                        <p class="text-secondary-card">Venimos de una empresa familiar que arrancó hace mucho tiempo con
                          valores que debemos preservar. Apoyados sobre sus bases, debemos generar un proceso de cambio
                          en toda la organización hacia nuevas formas de trabajar y pensar, utilizando tecnologías
                          digitales, sociales, móviles y emergentes.</p>
                        <p class="text-secondary-card">La transformación comienza en tu mente, en nuestras mentes.</p>
                        <p class="text-secondary-card">Contamos con tu apoyo para poder desarrollar la Estrategia
                          2022-2025. La misma tendrá vida e irá mutando a medida que vayamos considerando una necesidad
                          de cambios y mejoras.</p>
                        <p class="text-secondary-card">Ahora bien, nuestra razón de ser, nuestro "porqué hacemos lo que
                          hacemos", se puede resumir en el siguiente <b>Propósito</b> que hemos definido para la
                          Empresa:</p>
                        <p class="text-secondary-card"><b>Conectamos a las Personas con el Deporte y la Moda</b></p>
                        <p class="text-secondary-card"><b>Brindando experiencias innovadoras a nuestros clientes,
                            contribuyendo a que alcancen su
                            bienestar y transformen su realidad.</b></p>
                        <p class="text-secondary-card">A su vez, los <b>Valores</b> que nos mueven y que serán
                          fundamentales para sostener nuestra
                          Cultura Organizacional son:</p>
                      </div>
                    </div>

                    <!-- <div class="row" style="padding: 20pt 50pt 0 20pt;">
                      <div class="col-4">
                        <div class="second-drawing p-2">
                          <p style="text-align: center;"><b>Integridad</b></p>
                          <p class="mini-text">(supone actuar de manera profesional, con honestidad, ética y respeto)</p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing p-2">
                          <p style="text-align: center;"><b>Compromiso y Responsabilidad</b></p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing p-4">
                          <p style="text-align: center;"><b>El Cliente primero</b></p>
                        </div>
                      </div>
                    </div>
                    <div class="row" style="padding: 0 50pt 0 20pt;">
                      <div class="col-4">
                        <div class="second-drawing p-4">
                          <p style="text-align: center;"><b>Innovación</b></p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing p-4">
                          <p style="text-align: center;"><b>Pasión</b></p>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing p-4">
                          <p style="text-align: center;"><b>Trabajo en Equipo</b></p>
                        </div>
                      </div>
                    </div> -->

                    <div class="row" style="padding: 0 50pt 0 20pt;">
                      <div class="col-4">
                        <div class="second-drawing">
                          <img src="img/alquimia/valores1.webp" class="" alt="..." />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing">
                          <img src="img/alquimia/valores2.webp" class="" alt="..." />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing">
                          <img src="img/alquimia/valores3.webp" class="" alt="..." />
                        </div>
                      </div>
                    </div>
                    <div class="row" style="padding: 0 50pt 0 20pt;">
                      <div class="col-4">
                        <div class="second-drawing">
                          <img src="img/alquimia/valores4.webp" class="" alt="..." />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing">
                          <img src="img/alquimia/valores5.webp" class="" alt="..." />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="second-drawing">
                          <img src="img/alquimia/valores6.webp" class="" alt="..." />
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <p class="text-secondary-card">Creemos firmemente en ellos y todos los que trabajamos en la
                          Empresa debemos hacerlo honrando los mismos, convencidos de que es el único camino para
                          alcanzar los objetivos.</p>
                        <p class="text-secondary-card">Agradecidos por tu esfuerzo diario, te mandamos un gran abrazo.
                        </p>
                        <p class="text-secondary-card" style="text-align: right;"><b>El Directorio</b></p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import config from "../../config";


export default {
  data() {
    return {
      news: [],
      loading: false,
      skeleton: true,
      url:process.env.NODE_ENV === "development" ? config.developmentUrl : config.productionUrl,
    };
  },
  components: {
  },
  mounted() {
  },
  methods: {

  },
};
</script>
<style scoped>
div.first-drawing {
  /* background-color: rgb(8, 45, 58); */
  /* border-radius: 5px; */
  /* color: white; */
  align-items: center;
  /* font-weight: 500;
  font-size: 12pt; */

}

div.second-drawing {
  /* background-color: rgb(33, 135, 172); */
  /* border-radius: 15px; */
  /* color: white; */
  align-items: center;
  /* font-weight: 500;
  font-size: 14pt; */
}

div.second-drawing>p.mini-text {
  font-weight: 200;
  font-size: 10pt;
  margin: 0px;
  padding: 0px;
  text-align: center;
}

.container-img {
  width: 100%;
  height: 200pt;
}

.card-notice {
  padding: 30pt 20pt 20pt 20pt !important;
}

.icon-comment {
  font-size: 15pt;
  margin-top: 6pt;
  display: flex;
  align-items: center;
  justify-content: right;
  color: rgb(33, 135, 172);
}

.date-notice {
  padding: 10pt 0 10pt 0;
  text-align: left;
  font-weight: 600;
  color: rgb(33, 135, 172);
}

.notice {
  padding-bottom: 10pt !important;
  border-style: solid;
  border-width: 3pt 0 3pt 0;
  border-color: rgb(33, 135, 172) !important;
}

.border-blue {
  border-style: solid !important;
  border-width: 0 0 6pt 0 !important;
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
}

.title-card {
  padding: 38pt 50pt 0 20pt;
  font-weight: 500;
  font-size: 28pt;
  text-align: left;
  color: rgb(33, 135, 172);
}

.subtitle {
  padding: 10pt 0pt 0 0pt;
  font-weight: 900;
  font-size: 20pt;
  text-align: left;
  color: rgb(33, 135, 172);
  height: 70pt;
}

.text-secondary-mini-card {
  padding: 0pt 0pt 0 0pt;
  font-weight: 500;
  font-size: 13pt;
  text-align: left;
  color: rgb(33, 135, 172);
  height: 55pt;
}

.text-secondary-card {
  padding: 20pt 20pt 0 20pt;
  font-weight: 500;
  font-size: 13pt;
  text-align: left;
  color: rgb(33, 135, 172);
}

.input input {
  border-color: rgb(33, 135, 172) !important;
  border-radius: 0%;
}

.btn-input {
  background-color: rgb(33, 135, 172);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separator {
  margin-top: 25pt !important;
  margin-left: 20pt;
  padding-top: 2pt;
  border-style: solid;
  border-width: 0 0 0 4pt;
}

.bton {
  height: 23pt;
}

.icon {
  background-color: rgb(33, 135, 172);
  border-radius: 50%;
  color: white;
  height: 40pt;
  width: 40pt;
  font-size: 18pt;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1200px) {
  .container-img {
    height: 180pt;
  }

  .title-card {
    padding: 45pt 40pt 0 15pt;
    font-size: 18pt;
  }

  .text-secondary-card {
    margin-top: 10pt !important;
    padding: 15pt 15pt 0 15pt;
    font-weight: 500;
    font-size: 11pt;
  }

  .separator {
    margin-top: 20pt !important;
    margin-left: 20pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }

  .subtitle {
    font-size: 16pt;
    padding-top: 5pt;
    height: 53pt;
  }

  .text-secondary-mini-card {
    font-weight: 500;
    font-size: 11pt;
    text-align: left;
    color: rgb(33, 135, 172);
    height: 50pt;
  }

  .icon {
    height: 33pt;
    width: 33pt;
    font-size: 16pt;
  }
}

@media (max-width: 991px) {
  .container-img {
    height: 145pt;
  }

  .title-card {
    padding: 25pt 40pt 0 10pt;
    font-size: 16pt;
  }

  .text-secondary-card {
    margin-top: 0pt !important;
    padding: 10pt 10pt 0 10pt;
    font-weight: 500;
    font-size: 9pt;
  }

  .separator {
    padding-left: pt !important;
    margin-top: 10pt !important;
    margin-left: 10pt;
    padding-top: 2pt;
    border-style: solid;
    border-width: 0 0 0 4pt;
  }

  .input input {
    border-color: rgb(33, 135, 172) !important;
    border-radius: 0%;
    height: 14pt;
    font-size: 9pt;
  }

  .btn-input {
    height: 14pt;
    font-size: 9pt;
    background-color: rgb(33, 135, 172);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .subtitle {
    font-size: 13pt;
    height: 40pt;
  }

  .text-secondary-mini-card {
    padding-top: 5pt;
    font-weight: 500;
    font-size: 9pt;
    text-align: left;
    color: rgb(33, 135, 172);
    height: 45pt;
  }

  .card-notice {
    padding: 20pt 20pt 15pt 20pt !important;
  }

  .icon {
    height: 25pt;
    width: 25pt;
    font-size: 14pt;
  }
}

@media (max-width: 768px) {
  .container-img {
    height: 185pt;
  }

  .subtitle {
    height: 40pt;
  }

  .text-secondary-mini-card {
    height: 30pt;
  }

  .separador-small {
    padding-bottom: 20pt;
  }

  .img-portada img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    left: 0;
    object-fit: cover;
    padding: 0 11pt 0 11pt;
  }

  .img-portada {
    position: relative;
    height: 200pt;
  }

  .card-notice {
    padding: 20pt 10pt 15pt 10pt !important;
  }
}

@media (max-width: 440px) {
  .container-img {
    height: 150pt;
  }

  .subtitle {
    height: 40pt;
  }

  .card-notice {
    padding: 20pt 10pt 0 10pt !important;
  }

  .text-secondary-mini-card {
    height: 45pt;
  }
}
</style>